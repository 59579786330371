import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  loading:false
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'clearStepRegister':
      return { ...state, ...{ OTPRefcode: null, userProfile: null, message:null } }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
