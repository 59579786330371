import React, { Component } from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import PrivateRoute from './Route/Privateroute'
import PublicRoute from './Route/Publicroute'
import './index.css'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
const isAuthenticated = localStorage.getItem('Token')

//Route

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const OPTRequest = React.lazy(() => import('./views/pages/otp/OTP'))
const ValidateProfile = React.lazy(() => import('./views/pages/validateprofile'))
const OTPLogin = React.lazy(()=>import('./views/pages/otplogin'))
const PDFPage = React.lazy(() => import('./views/pages/pdfpage'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <PublicRoute path="/login" name="Login Page" isAuthenticated={isAuthenticated}>
              <Login />
            </PublicRoute>
            <PublicRoute path="/register" name="Register Page" isAuthenticated={isAuthenticated}>
              <Register />
            </PublicRoute>
            <PublicRoute
              path="/otprequest"
              name="Request OTP Page"
              isAuthenticated={isAuthenticated}
            >
              <OPTRequest />
            </PublicRoute>
            <PublicRoute
              path="/userprofile"
              name="Userprofile Page"
              isAuthenticated={isAuthenticated}
            >
              <ValidateProfile />
            </PublicRoute>
            <PublicRoute path="/pdfpage" name="Pdfview Page" isAuthenticated={isAuthenticated}>
              <PDFPage />
            </PublicRoute>
            <PublicRoute path="/otplogin" name="OTPlogin Page" isAuthenticated={isAuthenticated}>
              <OTPLogin />
            </PublicRoute>
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <PrivateRoute path="/" isAuthenticated={isAuthenticated}>
              <DefaultLayout />
            </PrivateRoute>
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    )
  }
}

export default App
